.dash-btn-nav{
    background: transparent !important;
    color: rgba(0, 0, 0, 0.5);
    border: 0px;
}

.dropdown-menu {         
    max-height: 875px;
    overflow-y: auto;
  }
