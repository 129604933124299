.dashboard-tiles{
    background-color: white !important;
    color: black !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    height: 100%;
    border-radius: 25px;
}
.dashboard-header{
    color: black !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    /*background-image: url("https://blog.casemed.com/hubfs/Hand%20holding%20globe%20against%20blue%20sky%20and%20mountains.jpg");*/ /* The image used */
    background-color: #FFFFFF; /* Used if the image is unavailable */
    height: 500px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    text-shadow: 1px 1px #ffffff;
}
.jumbotron{
  background: #354a5a;
  color: white !important;
}
.card{
  background: #354a5a !important;
  color: white !important;
}
.react-calendar{
  background: #354a5a !important;
  border: 0px !important;
}
.react-calendar__tile--now{
  background: #000 !important;
}
.react-calendar__month-view__days__day--weekend{
  color: #fff !important;
}
.dash-nav{
  background: #354a5a !important;
}
.dash-btn{
  background: #354a5a !important;
}
#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #771f25;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  #modloader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #771f25;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }
  
  @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  
  @keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }
  